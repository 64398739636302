import React from 'react';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

const ENTER_TIMEOUT = 750;
const EXIT_TIMEOUT = 1000;

class Transition extends React.PureComponent {
  render() {
    const {children, location, topLevelPage} = this.props;
    const transitionClass = topLevelPage ? 'transition' : 'transition--reverse';
    return (
      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          timeout={{
            enter: ENTER_TIMEOUT,
            exit: EXIT_TIMEOUT,
          }}
          classNames={transitionClass}
        >
          <div className="transition">{children}</div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}
export default Transition;
