import React, {createContext} from 'react';

const defaultValues = {
  testimonialClientLinks: null,
};

export const Context = createContext(defaultValues);

export const Provider = ({children}) => {
  return (
    <Context.Provider
      value={{
        ...defaultValues,
      }}
    >
      {children}
    </Context.Provider>
  );
};
