import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Link, useStaticQuery, graphql} from 'gatsby';
import {motion} from 'framer-motion';

const SiteNavContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (min-width: 769px) {
    width: 50%;
    min-width: 600px;
  }
`;

const MainNav = styled.nav`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background: var(--black);
  visibility: hidden;
  opacity: 0;
  transform: translateY(-1%);
  transition:
    opacity 0.6s ease-in-out,
    visibility 0.6s ease-in-out,
    transform 0.6s ease-in-out;
  z-index: -2;
  padding: calc(var(--gutter) * 4) var(--gutter) calc(var(--gutter) * 3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @media (min-height: 660px) and (min-width: 769px) {
    min-height: 660px;
    max-height: 100vh;
  }

  &[aria-expanded='true'] {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  *::selection {
    color: var(--text-color);
    background: var(--white);
  }

  *::-moz-selection {
    color: var(--text-color);
    background: var(--white);
  }
`;

const MainNavOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  height: 100vh;
  transform: translate3d(-200%, 0, 0);
  transform: translateY(-100%);
  visibility: hidden;
  opacity: 0;

  &[aria-expanded='true'] {
    transform: translate3d(-100%, 0, 0);
    opacity: 1;
    visibility: visible;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavList = styled(motion.ul)`
  list-style-type: none;
  margin: 0 0 calc(var(--gutter) * 2);
  transition: color 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  li {
    margin-bottom: 0;
  }

  @media (hover: hover) {
    &:hover a,
    &:focus a {
      color: rgba(255, 255, 255, 0.3);
    }
  }
`;

const NavItem = styled(motion.li)`
  a {
    text-decoration: none;
    display: block;
    color: var(--white);
    font-size: 24px;
    padding: 2px;
    transition: color 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

    @media (hover: hover) {
      &:hover,
      &:focus {
        color: var(--white);
      }
    }
  }
`;

const MenuFooterWrapper = styled(motion.div)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const SocialMenu = styled.nav`
  font-size: 14px;
  padding-right: var(--gutter);
  margin-bottom: calc(var(--gutter) * 4);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;

  .social-menu--wrapper {
    display: flex;
    flex-direction: column;
    transition: color 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

    @media screen and (max-width: 480px) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    @media (hover: hover) {
      &:hover a,
      &:focus a {
        color: rgba(255, 255, 255, 0.3);
      }

      a {
        transition: color 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
        &:hover,
        &:focus {
          color: var(--white);
        }
      }
    }
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
    width: 50%;
    flex-direction: column;
  }

  a {
    display: inline-block;
    color: var(--white);
    text-decoration: none;
    padding-right: calc(var(--gutter) * 2);

    @media (hover: hover) {
      &:hover {
        color: var(--white);
      }
    }
  }
`;

const ContactDetails = styled.div`
  width: 100%;
  margin-bottom: var(--gutter);

  @media (min-width: 768px) {
    width: 50%;
    padding-right: var(--gutter);
    margin-bottom: 0;
  }

  p {
    color: var(--white);
  }

  a {
    color: var(--white) !important;
  }
`;

const MainNavContainer = ({open, navList, closeMenu}) => {
  const navExpanded = (open) => {
    const navLinks = document.querySelectorAll('.nav__link');
    const headerLogo = document.querySelector('.logo');
    const htmlEl = document.querySelector('html');
    if (open && navLinks) {
      navLinks.forEach((link) => {
        link.classList.add('white');
      });
      if (headerLogo) headerLogo.classList.add('white');
      if (htmlEl) htmlEl.classList.add('scroll-lock');
    } else if (!open && navLinks) {
      navLinks.forEach((link) => {
        link.classList.remove('white');
      });
      if (headerLogo) headerLogo.classList.remove('white');
      if (htmlEl) htmlEl.classList.remove('scroll-lock');
    }
  };
  useEffect(() => {
    navExpanded(open);
  }, [open]);

  const ulVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25,
        ease: [0.25, 0.5, 0.25, 1],
        duration: 0.95,
        staggerChildren: 0.11875,
        staggerDirection: 1,
      },
    },
    closed: {
      opacity: 0,
      y: -100,
    },
  };

  const liVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  };

  const variants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.9,
        ease: [0.25, 0.75, 0.25, 1],
        duration: 1.12,
      },
    },
    closed: {
      opacity: 0,
      y: 90,
    },
  };

  return (
    <>
      <MainNav aria-label="Main Menu" aria-expanded={open} className="main-nav">
        <NavList animate={open ? 'open' : 'closed'} variants={ulVariants} transition={{duration: 2, type: 'tween'}}>
          {navList.map(
            (item) =>
              item && (
                <NavItem key={item.id} variants={liVariants}>
                  <Link to={item.slug} onClick={closeMenu}>
                    {item.title}
                  </Link>
                </NavItem>
              )
          )}
        </NavList>
        <FooterNav open={open} variants={variants} />
      </MainNav>
      <MainNavOverlay role="button" aria-expanded={open} onClick={closeMenu}></MainNavOverlay>
    </>
  );
};

const FooterNav = ({open, variants}) => {
  const {datoCmsFooter, datoCmsGlobalContent} = useStaticQuery(graphql`
    query FooterMenuQueryMenu {
      datoCmsFooter {
        socialLinks {
          id
          url
          title
          internal {
            type
          }
        }
      }
      datoCmsGlobalContent {
        contactDetails
        contactEmail
        phoneNumber
      }
    }
  `);
  const socialLinks = datoCmsFooter.socialLinks;

  return (
    <MenuFooterWrapper animate={open ? 'open' : 'closed'} variants={variants}>
      <ContactDetails>
        <div
          dangerouslySetInnerHTML={{
            __html: datoCmsGlobalContent.contactDetails,
          }}
        />
        <p>
          {datoCmsGlobalContent && datoCmsGlobalContent.contactEmail && (
            <a href={`mailto:${datoCmsGlobalContent.contactEmail}`} rel="noopener noreferrer">
              {datoCmsGlobalContent.contactEmail}
            </a>
          )}
          <br />
          {datoCmsGlobalContent && datoCmsGlobalContent.phoneNumber && (
            <a href={`tel:${datoCmsGlobalContent.phoneNumber}`} rel="noopener noreferrer">
              {datoCmsGlobalContent.phoneNumber}
            </a>
          )}
        </p>
      </ContactDetails>
      <SocialMenu>
        <div className="social-menu--wrapper">
          {socialLinks.map((link, index) => {
            if (link.internal.type === 'DatoCmsExternalLink')
              return (
                link && (
                  <React.Fragment key={link.id}>
                    <a href={link.url} target="_blank" rel="noreferrer noopener">
                      {link.title}
                    </a>
                  </React.Fragment>
                )
              );
            return (
              <React.Fragment key={link.id}>
                <Link to={link.slug}>{link.title}</Link>
              </React.Fragment>
            );
          })}
        </div>
      </SocialMenu>
    </MenuFooterWrapper>
  );
};

const SiteNav = ({navList, location, open, closeMenu}) => {
  return (
    <>
      <SiteNavContainer>
        <MainNavContainer open={open} navList={navList} closeMenu={closeMenu} />
      </SiteNavContainer>
    </>
  );
};

export default SiteNav;
