import styled from 'styled-components';
import {Link} from 'gatsby';

const LinkButton = styled(Link)`
  background-color: var(--black);
  border: 1px solid transparent;
  color: var(--white);
  font-size: 1em;
  text-decoration: none;
  transition: background-color 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  padding: 11px calc(var(--gutter) * 2);
  cursor: pointer;

  &.LinkButton__secondary {
    color: var(--grey);
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: transparent;
    border: 1px solid var(--border-color);
  }

  &:hover,
  &:focus {
    color: var(--white);
    background-color: var(--text-color);
  }
`;

export default LinkButton;
