import React, {useState} from 'react';
import styled from 'styled-components';

const Form = styled.form`
  position: relative;

  input[type='email'] {
    padding-right: 80px;
  }
`;

const SubmitForm = styled.button.attrs((props) => ({
  type: 'submit',
  name: 'Subscribe',
}))`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  color: var(--grey);
  background: transparent;
  border: 0;
  padding: 0.5em 0;
  cursor: pointer;
  transition: color 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);

  &:hover {
    color: var(--text-color);
  }
`;

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  return (
    <div>
      <h3>Newsletter</h3>
      <Form
        className="js-cm-form"
        id="subForm"
        action="https://www.createsend.com/t/subscribeerror?description="
        method="post"
        data-id="5B5E7037DA78A748374AD499497E309E6783F72BBAE5A8850E42F00B9A0613CB82FDB95D9B73FA2B84278F7AAD8F37778366B970ABAE49EEFF74F709E77AB871"
      >
        <input
          type="email"
          autoComplete="Email"
          aria-label="Email"
          className="js-cm-email-input"
          name="cm-httubk-httubk"
          id="fieldEmail"
          placeholder="Enter email address"
          required
          defaultValue={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <SubmitForm className="js-cm-submit-button">Subscribe</SubmitForm>
      </Form>

      <script src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js" defer></script>
    </div>
  );
};

export default NewsletterForm;
