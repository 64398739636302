import React, {useState} from 'react';
import {Link, graphql, useStaticQuery} from 'gatsby';
import styled from 'styled-components';
import IconPlus from '../assets/icon-plus.svg';
import IconKnowledge from '../assets/icon-knowledge.svg';
import Grid from './Grid';
import NewsletterForm from './NewsletterForm';
import NewsletterModal from './NewsletterModal';
import LinkButton from './LinkButton';

const FooterWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
`;

const FooterTag = styled.footer`
  position: relative;
  background-color: #ffffff;
  padding: calc(var(--gutter) * 2) 0 calc(var(--gutter) / 2);
  z-index: 2;

  @media screen and (min-width: 769px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;

    .grid:first-of-type {
      border-bottom: 1px solid var(--border-color);
    }
  }

  .grid:first-of-type {
    margin-bottom: 20px;
  }

  .grid-item {
    padding: 0 0 calc(var(--gutter) * 2);

    @media screen and (max-width: 768px) {
      border-bottom: 1px solid var(--border-color);
      margin-bottom: var(--gutter);
    }
  }

  .grid-item__content {
    max-width: 460px;
  }

  .button {
    display: inline-block;
    margin-top: var(--gutter);
  }

  .grid-item--flex {
    display: flex;
  }

  @media screen and (max-width: 480px) {
    .button {
      width: 100%;
      text-align: center;
    }
  }

  .copyright {
    font-size: 14px;
    color: var(--grey);
  }
`;

const WorkWithUs = styled.div`
  p {
    color: var(--grey);
  }
`;

export const FooterMenu = styled.nav`
  display: flex;
  margin-left: var(--gutter);

  a {
    position: relative;
    margin: 0 10px;
    color: var(--grey);
    font-size: 14px;
    transition:
      color 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95),
      border-color 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    white-space: nowrap;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

const ToggleModal = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  margin-right: var(--gutter);

  svg {
    margin-left: 5px;
  }
`;

const Column = styled.div`
  width: 100%;
  padding: var(--gutter) 0;
  border-top: ${(props) => (props.border ? '1px solid rgba(40, 40, 40, 0.6)' : 0)};
  transform: rotate(0);
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 769px) {
    padding: 0;
    border: 0;
    display: block;
    text-align: ${(props) => props.$textAlign || 'left'};
  }
`;

const SocialLinks = styled.ul`
  list-style: none;
  margin: 0;

  li {
    margin-bottom: 0;
  }

  a {
    display: inline-block;
    padding-bottom: 3px;
    color: var(--grey);
  }
`;

const Footer = () => {
  const [newsletterVisible, setNewsletterVisible] = useState(false);

  const {datoCmsFooter} = useStaticQuery(graphql`
    query FooterQuery {
      datoCmsFooter {
        workWithUsTitle
        workWithUsText
        workWithUsCta
        workWithUsLink
        footerMenu {
          ... on DatoCmsMenuEntry {
            id
            title
            slug
            internal {
              type
            }
          }
          ... on DatoCmsExternalLink {
            id
            url
            title
            internal {
              type
            }
          }
        }
        socialLinks {
          title
          url
          id
        }
        showNewsletterSignup
        showSearch
      }
    }
  `);

  const toggleNewsletter = () => {
    setNewsletterVisible(!newsletterVisible);
  };

  const footer = datoCmsFooter;

  const {workWithUsTitle, workWithUsText, workWithUsCta, workWithUsLink} = datoCmsFooter;

  return (
    <FooterWrapper className="site-footer">
      <FooterTag>
        <Grid>
          <WorkWithUs className="grid-item grid-item__half--left">
            <div className="grid-item__content">
              <h2 className="h2">{workWithUsTitle}</h2>
              <div dangerouslySetInnerHTML={{__html: workWithUsText}} />
              <LinkButton to={workWithUsLink} className="button">
                {workWithUsCta}
              </LinkButton>
            </div>
          </WorkWithUs>
          <div className="grid-item grid-item__half--right">
            <NewsletterForm />

            <SocialLinks>
              {footer.socialLinks.map(
                (link) =>
                  link && (
                    <li key={link.id}>
                      <a href={link.url} target="_blank" rel="noreferrer noopener">
                        {link.title}
                      </a>
                    </li>
                  )
              )}
            </SocialLinks>
          </div>
        </Grid>
        <Grid>
          <div className="grid-item__half--left small-down--hide">
            {footer.showNewsletterSignup && (
              <Column>
                <ToggleModal onClick={toggleNewsletter}>
                  Newsletter
                  <IconPlus />
                </ToggleModal>
              </Column>
            )}
            <IconKnowledge />
          </div>
          <div className="grid-item__half--right grid-item--flex">
            {footer.showSearch && <ToggleModal>Search</ToggleModal>}
            <span className="copyright">&copy; {new Date().getFullYear()} TWP</span>
            <FooterMenu>
              {footer.footerMenu.map((link, index) => {
                if (link.internal.type === 'DatoCmsExternalLink')
                  return (
                    link && (
                      <a key={link.id} href={link.url} target="_blank" rel="noreferrer noopener">
                        {link.title}
                      </a>
                    )
                  );
                return (
                  <Link key={link.id} to={link.slug}>
                    {link.title}
                  </Link>
                );
              })}
            </FooterMenu>
          </div>
        </Grid>

        {footer.showNewsletterSignup && (
          <NewsletterModal onClickHandler={toggleNewsletter} visible={newsletterVisible} />
        )}
      </FooterTag>
    </FooterWrapper>
  );
};

export default Footer;
