import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {debounce} from 'lodash';
import IconPlus from '../assets/icon-plus.svg';
import IconClose from '../assets/icon-close.svg';
import Grid from './Grid';
import {AnchorLink} from 'gatsby-plugin-anchor-links';

const Container = styled.div`
  flex: 0 1 100%;

  ul {
    list-style: none;
    text-align: left;
    margin: 0;
  }

  li {
    margin-bottom: 5px;
  }

  a {
    text-decoration: none;
    color: var(--grey-60);
    margin-right: 0;
  }

  button {
    padding: 0;
    margin-bottom: 0;
  }
`;

const FiltersListContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: var(--gutter) 0 calc(var(--gutter) * 2);
  background-color: var(--bgColorLight);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in;

  @media screen and (min-width: 769px) {
    top: 40px;
  }

  &[aria-expanded='true'] {
    opacity: 1;
    visibility: visible;
  }

  .filters__link--active {
    color: var(--text-color);
  }
`;

const FiltersToggle = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  color: var(--text-color);
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-property: color;

  &.filters-toggle {
    opacity: 0;
    visibility: hidden;
    transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
    transition-property: visibility, opacity;
  }

  body.filter-visible & {
    &.filters-toggle {
      visibility: visible;
      opacity: 1;
    }
  }

  @media screen and (min-width: 769px) {
    position: absolute;
    z-index: 100;
    top: var(--gutter);

    .main-nav--open & {
      display: none;
    }
  }

  svg {
    margin-left: 5px;
    vertical-align: middle;
    fill: var(--text-color);
    transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
    transition-property: fill;
  }

  &:hover {
    color: var(--text-color-faded);

    svg {
      fill: var(--text-color-faded);
    }
  }

  .icon-close {
    display: none;
  }

  .filters-expanded & {
    .icon-plus {
      display: none;
    }

    .icon-close {
      display: inline-block;
    }
  }
`;

const ClientList = styled.ul`
  grid-column: 1 / -1;
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
  margin-top: var(--gutter);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--gutter);

  @media (min-width: 769px) {
    grid-column: 7 / -1;
    margin-top: 45px;
  }

  li {
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 0;

    a {
      display: inline-block;
      padding: 8px 0 6px;
    }

    @media (min-width: 769px) {
      max-width: 220px;
    }
  }
`;

const TestimonialsFilters = ({testimonials}) => {
  const [filtersExpanded, setFiltersExpanded] = useState(false);

  const toggleFilters = () => {
    setFiltersExpanded(!filtersExpanded);
  };

  const handleScroll = (evt) => {
    if (window.scrollY >= 650) {
      document.body.classList.add('filter-visible');
    } else {
      document.body.classList.remove('filter-visible');
      setFiltersExpanded(false);
    }
  };

  useEffect(() => {
    if (window.scrollY >= 650) {
      document.body.classList.add('filter-visible');
    }
    const debouncedHandleScroll = debounce(handleScroll, 10);
    document.addEventListener('scroll', () => {
      setFiltersExpanded(false);
    });
    document.addEventListener('scroll', debouncedHandleScroll);
    return () => document.removeEventListener('scroll', debouncedHandleScroll);
  }, []);

  return (
    <Container className={`medium-down--hide ${filtersExpanded ? 'filters-expanded' : ''}`}>
      <Grid>
        <div className="grid-item__half--right">
          <FiltersToggle onClick={toggleFilters} className="filters-toggle">
            Clients
            <IconPlus />
            <IconClose />
          </FiltersToggle>
        </div>
      </Grid>
      <FiltersListContainer aria-expanded={filtersExpanded}>
        <Grid>
          <ClientList>
            {testimonials.length > 0 ? (
              testimonials.map(({id, clientName}) =>
                clientName ? (
                  <li key={id}>
                    <div className={filtersExpanded ? 'filters-expanded' : ''}>
                      <AnchorLink to={`/testimonials/#${id}`} onClick={toggleFilters} stripHash>
                        {clientName}
                      </AnchorLink>
                    </div>
                  </li>
                ) : (
                  <></>
                )
              )
            ) : (
              <></>
            )}
          </ClientList>
        </Grid>
      </FiltersListContainer>
    </Container>
  );
};

export default TestimonialsFilters;
