import React from 'react';
import LogoSvg from '../../assets/logo.svg';

const Logo = () => {
  return (
    <>
      <LogoSvg className="logo" />
    </>
  );
};

export default Logo;
