import React, {useState} from 'react';
import styled from 'styled-components';
import IconClose from '../assets/icon-close.svg';

const ToggleModal = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  margin-right: var(--gutter);

  svg {
    margin-left: 5px;
  }
`;

const Newsletter = styled.div`
  padding: var(--gutter);
  background: var(--text-color);
  position: fixed;
  bottom: 0;
  left: 0;
  transform: ${(props) => (props.visible ? 'translateY(calc(0% + 20px))' : 'translateY(150%)')};
  transition: transform 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  color: var(--white);
  ${ToggleModal} {
    color: var(--white) !important;
  }

  p {
    margin-top: var(--gutter);
    color: var(--white);
  }

  @media screen and (min-width: 769px) {
    max-width: 375px;
  }
`;

const NewsletterForm = styled.form`
  margin-top: var(--gutter);
`;

const FloatingLabel = styled.div`
  margin-bottom: calc(var(--gutter) * 1.5);
  position: relative;
`;
const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  transition:
    top 0.1s ease-in,
    font-size 0.1s cubic-bezier(0.77, 0, 0.175, 1);
`;

const Input = styled.input`
  .NewsletterModal__Newsletter-djGDzB & {
    border: 0;
    width: 100%;
    border-bottom: 1px solid var(--white);
    background: transparent;
    color: var(--white);
  }

  &:focus,
  &:valid {
    + ${Label} {
      top: -15px;
      font-size: 10px;
    }
  }
`;

const SubmitForm = styled.button.attrs((props) => ({
  type: 'submit',
  name: 'Submit Form',
}))`
  background: transparent;
  color: var(--white);
  border: 0;
  padding: 0;
`;

const NewsletterModal = ({visible, onClickHandler}) => {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');

  return (
    <Newsletter visible={visible}>
      <ToggleModal onClick={onClickHandler}>
        Newsletter
        <IconClose />
      </ToggleModal>

      <p>Stay informed and inspired with our monthly newsletter</p>

      <NewsletterForm
        className="js-cm-form"
        id="subForm"
        action="https://www.createsend.com/t/subscribeerror?description="
        method="post"
        data-id="5B5E7037DA78A748374AD499497E309E6783F72BBAE5A8850E42F00B9A0613CB82FDB95D9B73FA2B84278F7AAD8F37778366B970ABAE49EEFF74F709E77AB871"
      >
        <FloatingLabel>
          <Input
            type="text"
            aria-label="Name"
            id="fieldName"
            name="cm-name"
            required
            defaultValue={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Label htmlFor="fieldName">First Name</Label>
        </FloatingLabel>
        <FloatingLabel>
          <Input
            type="email"
            autoComplete="Email"
            aria-label="Email"
            className="js-cm-email-input"
            name="cm-httubk-httubk"
            id="fieldEmail"
            required
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Label htmlFor="fieldEmail">Email</Label>
        </FloatingLabel>
        <SubmitForm>Submit</SubmitForm>
      </NewsletterForm>
    </Newsletter>
  );
};

export default NewsletterModal;
