import React, {useEffect} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Transition from './Transition';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/layout.scss';
import {useInView} from 'react-intersection-observer';

const Layout = (props) => {
  const {children, location, pageContext} = props;
  const topLevelPage = location.pathname.split('/').filter(Boolean).length < 2;
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const {ref, inView} = useInView({
    threshold: 0.9,
  });

  useEffect(() => {
    if (inView) {
      document.body.classList.add('footer-in-view');
      return;
    }
    document.body.classList.remove('footer-in-view');
  }, [inView]);

  return (
    <>
      <Header pageContext={pageContext} location={location} title={data.site.siteMetadata.title} />
      <main>
        <Transition location={location} topLevelPage={topLevelPage}>
          <div className="page-content">
            {children}
            <div ref={ref}>
              <Footer />
            </div>
          </div>
        </Transition>
      </main>
    </>
  );
};

export default Layout;
