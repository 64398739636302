import React from 'react';
import styled from 'styled-components';

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: var(--gutter);
  grid-row-gap: var(--gutter);
  padding: 0 var(--gutter);
  margin: 0 auto;
  width: 100%;
  max-width: var(--site-width);
`;

const Grid = ({children}) => <StyledGrid className="grid">{children}</StyledGrid>;

export default Grid;
