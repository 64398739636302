import React, {useState} from 'react';
import styled from 'styled-components';
import IconPlus from '../assets/icon-plus.svg';
import IconClose from '../assets/icon-close.svg';
import Grid from './Grid';
import kebabCase from 'lodash/kebabCase';

const Container = styled.div`
  flex: 0 1 100%;
  transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);

  @media screen and (max-width: 768px) {
    .scrolled & {
      opacity: 0;
      visibility: hidden;
    }
  }

  ul {
    list-style: none;
    display: inline-block;
    text-align: left;
    margin: 0;
  }

  li {
    margin-bottom: 5px;
  }

  a {
    text-decoration: none;
    color: var(--grey-60);
    margin-right: 0;
  }

  button {
    padding: 0;
    margin-bottom: 0;
  }
`;

const FiltersListContainer = styled.div`
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: calc(var(--gutter) * 4) 0 calc(var(--gutter) * 2);
  background-color: var(--bgColorLight);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in;

  @media screen and (min-width: 769px) {
    top: 40px;
    padding: var(--gutter) var(--gutter) calc(var(--gutter) * 2);
  }

  &[aria-expanded='true'] {
    opacity: 1;
    visibility: visible;
  }

  .filters__link--active {
    color: var(--text-color);
  }
`;

const FiltersToggle = styled.button`
  position: absolute;
  top: 100px;
  z-index: 100;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  color: var(--text-color);
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-property: color;

  svg {
    margin-left: 5px;
    vertical-align: middle;
    fill: var(--text-color);
    transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
    transition-property: fill;
  }

  &:hover {
    color: var(--text-color-faded);

    svg {
      fill: var(--text-color-faded);
    }
  }

  .main-nav--open & {
    display: none;
  }

  @media screen and (min-width: 769px) {
    top: var(--gutter);
  }

  .icon-close {
    display: none;
  }

  .filters-expanded & {
    .icon-plus {
      display: none;
    }

    .icon-close {
      display: inline-block;
    }
  }
`;

const JournalFilters = ({categories, location}) => {
  const [filtersExpanded, setFiltersExpanded] = useState(false);

  const toggleFilters = () => {
    setFiltersExpanded(!filtersExpanded);
  };

  const isJournalPage = location.pathname === '/journal/';

  return (
    <Container className={filtersExpanded ? 'filters-expanded' : ''}>
      <Grid>
        <div className="grid-item__half--right">
          <FiltersToggle onClick={toggleFilters} className="filters-toggle">
            Filters
            <IconPlus />
            <IconClose />
          </FiltersToggle>
        </div>
      </Grid>
      <FiltersListContainer aria-expanded={filtersExpanded}>
        <Grid>
          <div className="grid-item__half--right">
            <ul className="filters__list">
              <li className="filters__item">
                <a className={`filters__link ${isJournalPage ? 'filters__link--active' : null}`} href="/journal/">
                  All
                </a>
              </li>
              {categories.map((category, index) => {
                const slug = kebabCase(category);
                return (
                  category && (
                    <li className="filters__item" key={`${slug}-${index}`}>
                      <a
                        className={`filters__link ${location.pathname.includes(slug) ? 'filters__link--active' : null}`}
                        href={`/journal/category/${slug}`}
                      >
                        {category}
                      </a>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </Grid>
      </FiltersListContainer>
    </Container>
  );
};

export default JournalFilters;
