import styled from 'styled-components';
import {Link} from 'gatsby';

const StyledLink = styled(Link)`
  position: relative;
  color: var(--grey);
  font-size: 16px;
  text-decoration: none;
  transition: color 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);

  @media (hover: hover) {
    &:hover,
    &:focus {
      color: var(--text-color);
    }
  }
`;

export default StyledLink;
