import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import {graphql, useStaticQuery, Link} from 'gatsby';
import SiteNav from './SiteNav';
import {debounce} from 'lodash';
import JournalFilters from './JournalFilters';
import TestimonialsFilters from './TestimonialsFilters';
import StyledLink from './StyledLink';
import {AnchorLink} from 'gatsby-plugin-anchor-links';
import Logo from '../components/Index/Logo';

const HeaderTag = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  margin: 0 auto;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 110;
  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-property: background-color, transform, color;

  .nav__link,
  .logo {
    color: var(--white);
    fill: var(--white);
    transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
    transition-property: fill, color;
  }

  .dark & {
    transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
    transition-property: background-color, transform, color;

    .nav__link,
    .logo {
      color: var(--text-color-hex);
      fill: var(--text-color-hex);
    }
  }

  .header-background & {
    background-color: #f7f7f5;
    border-bottom: 1px solid rgba(31, 31, 31, 0.1);
    transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
    transition-property: background-color, transform, color;

    .nav__link,
    .logo {
      color: var(--text-color);
      fill: var(--text-color);
    }
  }

  .scrolled & {
    background-color: #f7f7f5;
    border-bottom: 1px solid rgba(31, 31, 31, 0.1);

    .nav__link,
    .logo {
      color: var(--text-color);
      fill: var(--text-color);
    }
  }

  .nav__link.white {
    color: var(--white);
  }

  @media screen and (max-width: 768px) {
    .logo.white {
      fill: var(--white);
    }
  }

  .main-nav--open & {
    background-color: transparent;
    border-bottom: none;

    svg.logo {
      fill: var(--white);
    }

    @media screen and (min-width: 769px) {
      background-color: var(--bgColorLight);
      border-bottom: 1px solid rgba(31, 31, 31, 0.1);

      svg.logo {
        fill: var(--text-color);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .scrolled & {
      background-color: var(--bgColorLight);
      border-bottom: 1px solid rgba(31, 31, 31, 0.1);
    }
  }

  > a {
    height: 60px;
    z-index: 1;
    display: flex;
    align-items: center;
  }

  .logo {
    display: block;
    width: 220px;
    margin: var(--gutter);

    @media screen and (min-width: 769px) {
      margin: calc(var(--gutter) / 2) var(--gutter);
    }
  }
`;

const BlendedGroup = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  display: flex;
  backface-visibility: hidden;
  z-index: 99;
  display: flex;
  align-items: center;

  .nav__links--primary {
    display: flex;
    align-items: center;
  }

  .nav__link--secondary {
    color: var(--text-color);
    padding-left: 10px;
    transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
    transition-property: opacity, color;

    &:hover {
      color: var(--text-color-faded);
    }
  }

  .main-nav--open & {
    mix-blend-mode: none;

    .nav__link--secondary {
      opacity: 0;
    }

    .nav__link,
    a,
    button {
      color: var(--white);
      mix-blend-mode: difference;

      &:hover,
      &:focus {
        color: var(--white);
      }
    }
  }

  a {
    padding: var(--gutter);
    opacity: 1;

    @media screen and (max-width: 768px) {
      opacity: 0;
      transform: translateY(-200px);
    }
  }

  .main-nav--open.scrolled &,
  .main-nav--open & {
    a {
      opacity: 1;
      transform: none;
    }
  }
`;

const NavToggle = styled.button`
  background: transparent;
  border: 0;
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 60px;
  color: var(--text-color);
  font-size: 16px;
  cursor: pointer;

  i {
    width: 10px;
    height: 10px;
    border: 1px solid currentColor;
    background-color: currentColor;
    display: inline-block;
    vertical-align: baseline;
    border-radius: 50%;
    margin-left: 10px;

    .main-nav--open & {
      background-color: transparent;
    }
  }
`;

const BackLink = styled.button`
  color: var(--text-color);
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  appearance: none;
  appearance: none;
  background: none;
  border: none;
  padding-left: 0;
`;

const HeaderLogo = ({title, closeMenu}) => {
  return (
    <React.Fragment>
      <Link to="/" onClick={closeMenu}>
        <Logo />
        <p className="visually-hidden">{title}</p>
      </Link>
    </React.Fragment>
  );
};

const Header = ({location, title, pageContext}) => {
  const data = useStaticQuery(graphql`
    query Header {
      datoCmsHeader {
        mainMenu {
          id
          slug
          title
        }
        siteLogo {
          url
        }
        enableJournalBlogFiltering
      }
      datoCmsTestimonialsPage {
        testimonials {
          clientName
          id
        }
      }
    }
  `);

  const [menuExpanded, setMenuExpanded] = useState(false);
  const toggleMenu = () => {
    setMenuExpanded(!menuExpanded);
  };
  const closeMenu = () => {
    setMenuExpanded(false);
  };

  const CURRENT_PAGE_MAP = {
    portfolio: 'Work',
    client: 'Work',
    journal: 'Journal',
    'about-us': 'About Us',
  };

  const getTopLevelPage = () => {
    if (location.pathname === '/') return 'Menu';
    const topLevel = location.pathname.split('/')[1];
    if (CURRENT_PAGE_MAP.hasOwnProperty(topLevel)) {
      return CURRENT_PAGE_MAP[topLevel];
    }

    return topLevel.split('-').join(' ').toUpperCase();
  };

  let currentPage = getTopLevelPage();

  const header = useRef(null);
  const handleScroll = (evt) => {
    if (window.scrollY >= 60) {
      document.body.classList.add('scrolled');
    } else {
      document.body.classList.remove('scrolled');
    }
  };

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 10);
    document.addEventListener('scroll', debouncedHandleScroll);
    return () => document.removeEventListener('scroll', debouncedHandleScroll);
  });

  const headerData = data.datoCmsHeader;

  // check page urls with regex to determine secondary nav link
  const isJournalPostPageRegex = /journal\/.+/g;
  let isJournalPostPage = location.pathname.match(isJournalPostPageRegex) !== null;

  const isFilteredJournalPostPage = location.pathname.indexOf('/journal/category/') > -1;
  if (isFilteredJournalPostPage) {
    isJournalPostPage = false;
  }

  const isPartnerPageRegex = /partners\/.+/g;
  const isPartnerPage = location.pathname.match(isPartnerPageRegex) !== null;

  const isCareerPageRegex = /careers\/.+/g;
  const isCareerPage = location.pathname.match(isCareerPageRegex) !== null;

  const isTestimonialsPageRegex = /testimonials.?/g;
  const isTestimonialsPage = location.pathname.match(isTestimonialsPageRegex) !== null;

  const isInfoPageRegex = /info\/.+/g;
  const isInfoPage = location.pathname.match(isInfoPageRegex) !== null;

  const hasSecondaryNavLink = isJournalPostPage || isPartnerPage || isCareerPage === true;
  // get link back to parent directory
  let backlink = location.pathname.split('/');

  // get link text depending on page
  let secondaryLinkText;
  switch (backlink[1]) {
    case 'journal':
      secondaryLinkText = 'Back to Journal';
      break;
    case 'partners':
      secondaryLinkText = 'Back to Partners';
      break;
    case 'info':
      secondaryLinkText = 'Back';
      break;
    default:
  }

  const goBack = (event) => {
    window.history.go(-1);
  };

  return (
    <>
      <HeaderTag className="site-header" ref={header}>
        <HeaderLogo logoUrl={headerData.siteLogo.url} closeMenu={closeMenu} />
        <SiteNav navList={headerData.mainMenu} location={location} open={menuExpanded} closeMenu={closeMenu} />
        {pageContext.showFilters && headerData.enableJournalBlogFiltering && (
          <JournalFilters categories={pageContext.filterCategories} location={location} />
        )}
        {isTestimonialsPage && <TestimonialsFilters testimonials={data.datoCmsTestimonialsPage.testimonials} />}
        <BlendedGroup className={hasSecondaryNavLink || isInfoPage ? 'space-between' : 'flex-end'}>
          {hasSecondaryNavLink && !isFilteredJournalPostPage && (
            <div className="nav__links--secondary">
              {isCareerPage ? (
                <AnchorLink
                  className="nav__link--secondary medium-down--hide"
                  to={`/careers/${backlink[2]}/#apply`}
                  stripHash
                >
                  Apply Now
                </AnchorLink>
              ) : (
                <StyledLink
                  className="nav__link--secondary medium-down--hide"
                  to={`/${backlink[1]}/`}
                  title={secondaryLinkText}
                >
                  {secondaryLinkText}
                </StyledLink>
              )}
            </div>
          )}
          {isInfoPage && (
            <div className="nav__links--secondary">
              <BackLink className="nav__link--secondary medium-down--hide" onClick={goBack}>
                Back
              </BackLink>
            </div>
          )}
          <div className="nav__links--primary">
            {currentPage !== 'CONTACT US' ? (
              <StyledLink
                className="nav__link small-down--hide"
                onClick={() => setMenuExpanded(false)}
                to={`/contact/`}
              >
                Get in touch
              </StyledLink>
            ) : (
              ''
            )}
            <NavToggle className="nav__link" onClick={toggleMenu} title="Expand Menu" aria-expanded={menuExpanded}>
              <span>Menu</span>
              <i></i>
            </NavToggle>
          </div>
        </BlendedGroup>
      </HeaderTag>
    </>
  );
};

export default Header;
